<template lang="pug">
    section#banner
        .controls
            button.left(@click="changeBanner(ativo - 1)")
                SvgIcon(data="@svgs/seta-ippuc.svg")
            button.right(@click="changeBanner(ativo + 1)")
                SvgIcon(data="@svgs/seta-ippuc.svg")
        .wrapper
            ul.items
                li(v-for="item, index in noticias", :class="{ ativo: index == ativo }", :style="itemStyle").item
                    .text
                        button(@click="shareContent(item.title, getCompleteLink(item.browser))").share
                            FontAwesomeIcon(:icon="icons.faShareNodes")
                        h2 {{ item.title }}
                        p {{ item.description }}
                        router-link(:to="{ name: 'Noticia', params: { slug: item.slug } }") Ver mais
                    Imagem(:contents="item.thumbnail")
</template>

<script>
import { components, shareContent, getCompleteLink } from '@/mixins/components'
import { faShareNodes, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'section-banner-galeria',
  components,
  data () {
    return {
      icons: {
        faShareNodes,
        faArrowUpRightFromSquare
      },
      ativo: 0,
      loop: null,
      noticias: []
    }
  },
  computed: {
    itemStyle () {
      return {
        transform: `translateX(${100 * this.ativo * -1}%)`
      }
    }
  },
  mounted () {
    this.loadNoticias()
  },
  methods: {
    changeBanner (index) {
      if (index < 0) { this.ativo = this.noticias.length - 1 } else if (index > this.noticias.length - 1) { this.ativo = 0 } else { this.ativo = index }
    },
    loadNoticias () {
      this.$axios
        .get('noticias/ultimas')
        .then(response => {
          this.noticias = response.data.map(item => {
            return Object.assign({}, item, {
              browser: {
                module: 'Noticias',
                slug: item.slug
              }
            })
          })
          this.loop = setInterval(() => {
            if (this.ativo < this.noticias.length - 1) { this.ativo++ } else { this.ativo = 0 }
          }, 8000)
        })
    },
    shareContent,
    getCompleteLink
  }
}
</script>

<style lang="stylus" scoped src="./BannerGaleria.styl"></style>
